<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-form
      v-if="forceRerender"
      ref="form"
      class="repeater-form pb-5 pt-1"
      :class="topLabelOnly ? 'top-label-only' : ''"
      @submit.prevent="repeateAgain"
    >
      <div :class="smallHeight ? '' : 'pb-5'">
        <b-row
          v-for="(item, index) in field"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col
            v-for="(formField, fieldIndex) in formFields"
            :key="formField.name"
            cols="12"
            :md="formField.colSize ? formField.colSize : columnSize(fieldIndex)"
            :class="smallHeight ? '' : 'mt-1'"
          >
            <FormField
              :module="module"
              :form-field="formField"
              :entity.sync="field[index]"
              :id-suffix="index.toString()"
              :append-to-body="true"
              :image-change-input="handleImageChange"
            />
          </b-col>
          <!-- Remove Button -->
          <b-col v-if="allowDelete" md="2" class="pt-1 text-right">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              :class="smallHeight ? '' : 'mt-2'"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>{{ $t('Delete') }} {{ title }}</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </div>
    </b-form>
    <b-button
      v-if="allowDelete"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>{{ $t('Add') }} {{ title }}</span>
    </b-button>
  </div>
</template>

<script>
import axios from '@/libs/axios'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { BForm, BRow, BCol, BButton } from 'bootstrap-vue'
import FormField from '@/layouts/entity/FormField.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    FormField,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    title: {
      type: String,
      required: true,
    },
    module: {
      type: String,
      required: true,
    },
    fieldEndpoint: {
      type: String,
      default: null,
      required: false,
    },
    field: {
      type: Array,
      required: true,
    },
    formFields: {
      type: Array,
      required: true,
    },
    smallHeight: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowDelete: {
      type: Boolean,
      default: true,
      required: false,
    },
    topLabelOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      blankItem: {},
      forceRerender: true,
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)

    this.initBlankItem()
  },
  mounted() {
    this.initTrHeight()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    columnSize(index) {
      const l = this.formFields.length
      return (
        Math.floor((this.allowDelete ? 10 : 12) / l) +
        (l % 2 === 0 && index === l - 1 ? 1 : 0)
      )
    },

    initBlankItem() {
      this.formFields.forEach((field) => {
        if (field.type === 'select' || field.type === 'selectmultiple') {
          this.blankItem[field.id] = null
        } else if (
          field.type === 'checkbox' ||
          field.type === 'checkboxgroup'
        ) {
          this.blankItem[field.id] = false
        } else {
          this.blankItem[field.id] = ''
        }
      })
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    repeateAgain() {
      // eslint-disable-next-line vue/no-mutating-props
      this.field.push(JSON.parse(JSON.stringify(this.blankItem)))
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },

    removeItem(index) {
      if (this.fieldEndpoint != null && this.field[index].id) {
        axios.delete(`${this.fieldEndpoint}/${this.field[index].id}`)
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.field.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    handleImageChange(input, formField, entity) {
      const file = input && input.files && input.files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          // eslint-disable-next-line no-param-reassign
          entity[formField.id] = reader.result
          this.rerenderComponent()
        },
        false
      )

      if (file) {
        reader.readAsDataURL(file)
      } else {
        // eslint-disable-next-line no-param-reassign
        entity[formField.id] = ''

        this.rerenderComponent()
      }
    },

    rerenderComponent() {
      // Due to improper reactivity we need to redraw the component
      // to display the changes
      this.forceRerender = false
      this.$nextTick(() => {
        this.forceRerender = true
      })
    },
  },
}
</script>

<style lang="scss">
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;

  .media {
    display: block;

    .b-avatar {
      width: 120px !important;
      height: 80px !important;
      margin-bottom: 10px;
    }

    .btn-outline-secondary {
      margin-top: 5px;
      margin-left: 0 !important;
    }
  }
}
</style>
