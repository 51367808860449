<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
<!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="accountFormFields"
            allow-autofocus
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="personalFormFields"
          />
        </b-tab>

        <!-- Tab: Social Links -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="LinkIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Social Links') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="socialFormFields"
          />
        </b-tab>

        <!-- Tab: Inner Page -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="LayoutIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Layout') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="layoutFields"
          />

          <LayoutPreview layout="company" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="PackageIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Shipping Options') }}</span>
          </template>
          <b-row>
            <b-col cols="12">
              <RepeaterField
                :module="config.module"
                :title="$t('Shipping Option')"
                :field="entity.companyCoverAreas"
                :form-fields="shippingOptionsFields"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard,
} from 'bootstrap-vue'
import { translationUtils } from '@core/mixins/ui/translations'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import RepeaterField from '@/layouts/entity/RepeaterField.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import LayoutPreview from '@/layouts/entity/LayoutPreview.vue'
import { getUserRights } from '@/auth/utils'
import storeModule from '../companyStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    RepeaterField,
    LayoutPreview,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        companyCoverAreas: [],
      },
      entityLoaded: false,
      bankOptions: [],
      coverAreaOptions: [],
      countryOptions: [],
      roleOptions: [],
      shippingClassOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'company',
        endpoint: 'company',
        route: 'companies',
        title: {
          single: this.$t('Company_'),
          plural: this.$t('Companies'),
        },
      }
    },
    accountFormFields() {
      return [
        {
          id: 'logo',
          type: 'image',
          label: this.$t('Featured Image'),
          colSize: 6,
        },
        {
          id: 'companyGallery',
          type: 'gallery',
          label: `${this.$t('Gallery')} (1920x700)`,
          colSize: 6,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'email',
          type: 'email',
          label: this.$t('email'),
          required: true,
          rules: 'email',
          colSize: 3,
        },
        {
          id: 'roles',
          type: 'selectmultiple',
          label: this.$t('role'),
          options: this.roleOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'website',
          type: 'text',
          label: this.$t('website'),
          required: false,
          rules: 'url',
          colSize: 3,
        },
        {
          id: 'description',
          type: 'textarea',
          label: this.$t('description'),
          required: false,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('Enabled'),
          colSize: 2,
        },
      ]
    },
    personalFormFields() {
      return [
        {
          id: 'country',
          object: true,
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'perioxh',
          type: 'text',
          label: this.$t('city'),
          required: false,
          colSize: 3,
        },
        {
          id: 'address',
          type: 'text',
          label: this.$t('address'),
          required: true,
          colSize: 3,
        },
        {
          id: 'postalCode',
          type: 'text',
          label: this.$t('postal code'),
          required: false,
          colSize: 3,
        },
        {
          id: 'phone',
          type: 'text',
          label: this.$t('telephone'),
          rules: 'phone',
          required: true,
          colSize: 3,
        },
        {
          id: 'companyName',
          type: 'text',
          label: this.$t('company name'),
          colSize: 3,
        },
        {
          id: 'vatId',
          type: 'text',
          label: this.$t('vat'),
          required: false,
          colSize: 3,
        },
        {
          id: 'vatOffice',
          type: 'text',
          label: this.$t('vat office'),
          required: false,
          colSize: 3,
        },
        {
          id: 'bank',
          object: true,
          type: 'select',
          label: this.$t('bank'),
          options: this.bankOptions,
          required: false,
          colSize: 3,
        },
        {
          id: 'iban',
          type: 'text',
          label: this.$t('iban'),
          required: false,
          colSize: 3,
        },
        {
          id: 'map',
          type: 'map',
          colSize: 12,
        },
      ]
    },
    socialFormFields() {
      return [
        {
          id: 'google',
          type: 'text',
          label: this.$t('google'),
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'facebook',
          type: 'text',
          label: this.$t('facebook'),
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'instagram',
          type: 'text',
          label: this.$t('instagram'),
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'linkedIn',
          type: 'text',
          label: this.$t('linkedin'),
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'twitter',
          type: 'text',
          label: this.$t('twitter'),
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'pinterest',
          type: 'text',
          label: this.$t('pinterest'),
          required: false,
          rules: 'url',
          colSize: 4,
        },
        {
          id: 'youtubeLink',
          type: 'text',
          label: this.$t('youtube'),
          required: false,
          rules: 'url',
          colSize: 4,
        },
      ]
    },
    layoutFields() {
      return [
        {
          id: 'hero_image',
          type: 'image',
          label: `${this.$t('Hero Image')}: (600x900)`,
          layoutPreview: 'company_hero_image',
          colSize: 6,
        },
        {
          id: 'contact_image',
          type: 'image',
          label: `${this.$t('Contact Image')}: (600x900)`,
          layoutPreview: 'company_contact_image',
          colSize: 6,
        },
        {
          id: 'content1',
          type: 'quill',
          label: `${this.$t('Content')} #1`,
          layoutPreview: 'company_content_1',
          colSize: 6,
        },
        {
          id: 'content2',
          type: 'quill',
          label: `${this.$t('Content')} #2`,
          layoutPreview: 'company_content_2',
          colSize: 6,
        },
        {
          id: 'image1',
          type: 'image',
          label: `${this.$t('Image')} #1: (600x900)`,
          layoutPreview: 'company_image_1',
          colSize: 6,
        },
        {
          id: 'image2',
          type: 'image',
          label: `${this.$t('Image')} #2: (600x900)`,
          layoutPreview: 'company_image_2',
          colSize: 6,
        },
      ]
    },
    shippingOptionsFields() {
      return [
        {
          id: 'coverArea',
          object: true,
          type: 'select',
          label: this.$t('Cover Area'),
          options: this.coverAreaOptions,
          required: false,
          colSize: 2,
        },
        {
          id: 'shippingCost',
          type: 'number',
          label: this.$t('Shipping Cost'),
          required: true,
          colSize: 2,
        },
        {
          id: 'freeShippingLimit',
          type: 'number',
          label: this.$t('Free Shipping Limit'),
          required: true,
          colSize: 2,
        },
        {
          id: 'shippingClass',
          object: true,
          type: 'select',
          label: this.$t('Shipping Class'),
          options: this.shippingClassOptions,
          required: false,
          colSize: 2,
        },
        {
          id: 'totalCostPerUnit',
          type: 'number',
          label: this.$t('Total Cost Per Unit'),
          required: true,
          colSize: 2,
        },
      ]
    },
  },
  created() {
    const rights = getUserRights()
  },
  mounted() {
    this.$store.dispatch('app-common/fetchBanks')
      .then(response => {
        this.bankOptions = response.data
      })
    this.$store.dispatch('app-common/fetchCoverAreas')
      .then(response => {
        this.coverAreaOptions = response.data
      })
    this.$store.dispatch('app-common/fetchCountries')
      .then(response => {
        this.countryOptions = response.data
      })
    this.$store.dispatch('app-common/fetchRoles')
      .then(response => {
        this.roleOptions = response.data
      })
    this.$store.dispatch('app-common/fetchShippingClasses')
      .then(response => {
        this.shippingClassOptions = response.data
      })
  },
}
</script>
